import React from "react"
import Img from "gatsby-image"

const ProductImage = ({ imgFluid, name, brand, }) => {
    const alt = `${brand} ${name}`;
    return (
        <>
            <figure className="figure d-block">
                <Img fluid={imgFluid} className="figure-img img-fluid" alt={alt} />
                <figcaption className="figure-caption text-right">(c) {brand}</figcaption>
            </figure>
        </>
    )
}

export default ProductImage




