import React from "react"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import ProductJumbo from "../components/product-jumbo"
import Rating from "../components/rating"
import ProductImage from "../components/product-image"
import ProductHighlights from "../components/product-highlights"
import ProductCTA from "../components/product-cta"
import ProductReviews from "../components/product-reviews"
import ProductQuestions from "../components/product-questions"
import ProductSummary from "../components/product-summary"
import ProductVideos from "../components/product-videos"
import ProductDetails from "../components/product-details"
import SimilarProducts from "../components/similar-products"
import CheapDesks from "../components/cheap-desks"

const StandingDesk = ({ primaryProduct, imgFluid }) => {

    const googleAnalyticsEventFunction = function (e, val = primaryProduct.name) {
        //e.preventDefault()
        trackCustomEvent({
            // string - required - The object that was interacted with (e.g.video)
            category: "Affiliate Link",
            // string - required - Type of interaction (e.g. 'play')
            action: "Click", // Click
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: val,
            // number - optional - Numeric value associated with the event. (e.g. A product ID)
            //value: val
        })
        console.log("click event: " + val)
    };

    return (
        <>
            <ProductJumbo
                productName={primaryProduct.name}
                brand={primaryProduct.brand}
                lead={primaryProduct.lead}
                numberOfReviews={primaryProduct.reviewsAmount}
                numberOfQuestions={primaryProduct.questionsTotal}
                numberOfVideos={primaryProduct.videos?.length}
            />
            <div className="row">
                <div className="col-sm">
                    <ProductImage
                        imgFluid={imgFluid}
                        name={primaryProduct.name}
                        brand={primaryProduct.brand}
                    />
                </div>
                <div className="col-sm">
                    <Rating
                        rating={primaryProduct.rating}
                        reviewsAffiliateLink={primaryProduct.reviewsAffiliateLink}
                        numberOfReviews={primaryProduct.reviewsAmount}
                        gaEventFunction={googleAnalyticsEventFunction}
                    />
                    <ProductHighlights
                        highlights={primaryProduct.highlights}
                    />
                    <ProductCTA
                        affiliateLink={primaryProduct.affiliateLink}
                        name={primaryProduct.name}
                        gaEventFunction={googleAnalyticsEventFunction}
                    />
                </div>
            </div>
            <CheapDesks />
            <div className="row">
                <div className="col-sm">
                    <ProductReviews
                        reviewsAffiliateLink={primaryProduct.reviewsAffiliateLink}
                        name={primaryProduct.name}
                        brand={primaryProduct.brand}
                        gaEventFunction={googleAnalyticsEventFunction}
                        reviewsAmount={primaryProduct.reviewsAmount}
                    />
                </div>
                <div className="col-sm">
                    <ProductQuestions
                        questionsAffiliateLink={primaryProduct.questionsAffiliateLink}
                        name={primaryProduct.name}
                        brand={primaryProduct.brand}
                        gaEventFunction={googleAnalyticsEventFunction}
                        questionsTotal={primaryProduct.questionsTotal}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <ProductSummary summary={primaryProduct.summary} />
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <SimilarProducts
                    />
                </div>
            </div>
            {
                primaryProduct.videos &&
                <div className="row">
                    <div className="col-sm">
                        <ProductVideos videos={primaryProduct.videos} />
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-sm">
                    <ProductDetails
                        brand={primaryProduct.brand}
                        price={primaryProduct.price}
                        heightMin={primaryProduct.heightMin}
                        adjustingDrive={primaryProduct.adjustingDrive}
                        memory={primaryProduct.memory}
                        dimensions={primaryProduct.dimensions}
                        warranty={primaryProduct.warranty}
                        weightLimit={primaryProduct.weightLimit}
                        weight={primaryProduct.weight}
                        collisionProtection={primaryProduct.collisionProtection}
                    />
                </div>
            </div>

        </>
    )
}



export default StandingDesk
