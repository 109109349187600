import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import StandingDesk from "../components/standing-desk"
import SEO from "../components/seo"

export default function ProductTemplate({ data }) {
  let productImageFluid = data.dataJson.imageSource.childImageSharp.fluid
  let title = data.dataJson.title;
  let description = data.dataJson.description;

  return (
    <Layout>
      <SEO title={title} description={description} />
      <StandingDesk primaryProduct={data.dataJson} imgFluid={productImageFluid} />
    </Layout>
  )
}

export const query = graphql`
  query($internalLink: String!) {
    dataJson(internalLink: {eq: $internalLink}) {
        id
        adjustingDrive
        affiliateLink
        brand
        cableManagement
        collisionProtection
        compartment
        description
        dimensions
        edgeProtection
        gaEventFunction
        gimmicks
        heightMax
        heightMin
        highlights
        imageSource {
          childImageSharp {
                fluid(maxWidth: 800) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
              }
            }
        }
        internalLink
        internalLinkAmp
        lead
        memory
        name
        notes
        ogDescription
        ogImage
        ogTitle
        ogType
        ogUrl
        price
        questionsAffiliateLink
        questionsTotal
        rating
        reviewsAffiliateLink
        reviewsAmount
        ruler
        similarProducts
        standingDesk
        summary
        tilt
        title
        videos
    }
  }
`