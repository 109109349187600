import React from "react"

const ProductDetails = ({ brand, price, heightMin, adjustingDrive, memory, dimensions, warranty, weightLimit, weight, collisionProtection }) => {
    return (
        <>
            <a name="details"></a>
            <h2>Details</h2>
            <table className="table table-striped table-responsive-md">
                <tbody>
                    <tr itemProp="brand" itemScope itemType="http://schema.org/Brand">
                        <th scope="row">Hersteller</th>
                        <td itemProp="name">{brand}</td>
                    </tr>

                    <tr>
                        <th scope="row">Preis</th>
                        <td>{price}</td>
                    </tr>
                    
                    <tr>
                        <th scope="row">Höhe</th>
                        <td>{heightMin}</td>
                    </tr>
                    
                    <tr>
                        <th scope="row">Antrieb</th>
                        <td>{adjustingDrive}</td>
                    </tr>
                    
                    <tr>
                        <th scope="row">Speicherfunktion</th>
                        <td>{memory}</td>
                    </tr>
                    
                    <tr>
                        <th scope="row">Maße</th>
                        <td>{dimensions}</td>
                    </tr>
                    
                    <tr>
                        <th scope="row">Garantie</th>
                        <td>{warranty}</td>
                    </tr>
                    
                    <tr>
                        <th scope="row">Tragfähigkeit</th>
                        <td>{weightLimit}</td>
                    </tr>
                    
                    <tr>
                    <th scope="row">Kollisionsschutz</th>
                    <td>{collisionProtection}</td>
                    </tr>
                    
                    <tr>
                        <th scope="row">Gewicht</th>
                        <td>{weight}</td>
                    </tr>
                    
                </tbody>
            </table>
        </>
    )
}

export default ProductDetails
