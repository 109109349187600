import React from "react"

const Rating = ({ rating, reviewsAffiliateLink, numberOfReviews, gaEventFunction }) => {
    if (!!rating) {
        const ratingSymbol = "⭐";
        const ratingFinal = ratingSymbol.repeat(rating);
        return (
            <>
                <h2>Bewertung</h2>
                <div itemProp="aggregateRating" itemScope itemType="http://schema.org/AggregateRating">
                    <div className="alert alert-primary" role="alert">
                        <a href={reviewsAffiliateLink} onClick={gaEventFunction}>
                            <span itemprop="ratingValue">{rating}</span> von <span itemprop="bestRating">5</span></a> {ratingFinal}
                    </div>

                    <span itemProp="ratingCount" style={{ display: 'none' }}>{numberOfReviews}</span>

                </div><br />
            </>
        )
    } else {
        return null;
    }
}

export default Rating

