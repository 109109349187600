import React from "react"

const ProductVideos = ({ videos }) => {
    return (
        <>
            <a name="videos"></a>
            <h2>Videos</h2>
            {
                videos.map((video, index) => {
                    return (
                        <div key={index}>
                            <p dangerouslySetInnerHTML={{ __html: video[2] }}></p>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item" src={video[1]} allow="autoplay; encrypted-media" allowFullScreen></iframe>
                            </div>
                            <br />
                        </div>
                    )
                })
            }
        </>
    )
}

export default ProductVideos
