import React from "react"

const ProductReviews = ({ reviewsAffiliateLink, name, brand, gaEventFunction, reviewsAmount }) => {
    const text = reviewsAmount > 0
        ? `Lies mehr als ${reviewsAmount} Erfahrungs- und Testberichte von anderen Besitzern eines ${brand} ${name}`
        : `Sei der Erste der einen Erfahrungs- und Testbericht zum ${brand} ${name} verfasst.`;
    const reviewButtonText = reviewsAmount > 0
        ? `${reviewsAmount}+ Erfahrungs- und Testberichte lesen`
        : 'Erfahrungs- und Testberichte';

    return (
        <>
        <div className="mb-5">
            <a name="reviews"></a>
            <h2>Erfahrungen und Testberichte</h2>
            <p>{text}</p>
            <a
                className="btn btn-success"
                href={reviewsAffiliateLink}
                onClick={gaEventFunction}
                role="button"
            >💬 {reviewButtonText}</a>
        </div>
        </>
    )
}

export default ProductReviews




