import React from "react"
import { Link } from "gatsby"

const ProductCTA = ({ affiliateLink, name, gaEventFunction }) => {
    return (
        <>
            <a
                itemProp="url"
                className="btn btn-success btn-block"
                href={affiliateLink}
                onClick={gaEventFunction}
                role="button"
            ><span role="img" aria-label="Shopping Cart">🛒</span> Zum Angebot</a>
            <br />
        </>
    )
}

export default ProductCTA
