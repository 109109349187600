import React from "react"

const ProductJumbo = ({ productName, brand, lead, numberOfReviews, numberOfQuestions, numberOfVideos }) => {
  return (
    <>
      <div className="container my-5">
        <h1 className="display-4 text-center">{brand} <span itemProp="name">{productName}</span></h1>
        <p className="lead text-center" dangerouslySetInnerHTML={{ __html: lead }}></p>

        <div className="btn-group" role="group" aria-label="On-page navigation">
          <a href="#reviews" className="btn btn-link" style={{ display: (numberOfReviews > 0) ? 'inline' : 'none' }}><span className="badge badge-primary">{numberOfReviews}</span> Erfahrungs- und Testberichte</a>
          <a href="#questions" className="btn btn-link" style={{ display: (numberOfQuestions > 0) ? 'inline' : 'none' }}><span className="badge badge-primary">{numberOfQuestions}</span> Fragen und Antworten</a>
          <a href="#summary" className="btn btn-link">Zusammenfassung</a>
          <a href="#videos" className="btn btn-link" style={{ display: (numberOfVideos > 0) ? 'inline' : 'none' }}><span className="badge badge-primary">{numberOfVideos}</span> Videos</a>
          <a href="#similarProducts" className="btn btn-link"><span className="badge badge-primary">3</span> Ähnliche Produkte</a>
          <a href="#details" className="btn btn-link">Details</a>
        </div>

      </div>
    </>
  )
}
export default ProductJumbo

