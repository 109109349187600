import React from "react"

const ProductHighlights = ({ highlights }) => {
    return (
        <>
            <a name="highlights"></a>
            <h2 className="mb-3">Highlights</h2>
            <ul>
            {
                highlights.map((highlight, index) => {
                    return (<li key={index}>{highlight}</li>)
                })
            }
            </ul>
            <br />
        </>
    )
}

export default ProductHighlights
