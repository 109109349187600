import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import ProductSimilar from "./product-similar";
import DataFlexispot from "../data/flexispot-h2w";
import DataSongmics from "../data/songmics.json";
import DataImperioLift from "../data/imperio-lift.json"

const CheapDesks = ({ data }) => {

    const googleAnalyticsEventFunctionFlexispot = function (e, val = "Flexispot-cheap") {
        //e.preventDefault()
        trackCustomEvent({
            category: "Affiliate Link",
            action: "Click",
            label: val,
        })
        console.log("click event: " + val)
    };

    const googleAnalyticsEventFunctionSongmics = function (e, val = "Songmics-cheap") {
        //e.preventDefault()
        trackCustomEvent({
            category: "Affiliate Link",
            action: "Click",
            label: val,
        })
        console.log("click event: " + val)
    };

    const googleAnalyticsEventFunctionImperioLift = function (e, val = "ImperioLift-cheap") {
        //e.preventDefault()
        trackCustomEvent({
            category: "Affiliate Link",
            action: "Click",
            label: val,
        })
        console.log("click event: " + val)
    };

    return (
        <>
            <h3 className="mb-3">Andere günstige Schreibtische</h3>
            <div className="container mb-5">
                <div className="row">
                    <div className="col-sm">
                        <a href={DataFlexispot.affiliateLink} onClick={googleAnalyticsEventFunctionFlexispot} className="font-weight-bold" style={{ "fontSize": "1.3rem" }}>{ DataFlexispot.brand } { DataFlexispot.name }</a>
                        <p className="font-weight-bold" style={{ "fontSize": "1.5rem" }}><span className="badge badge-warning">€ { DataFlexispot.price }</span></p>
                    </div>
                    <div className="col-sm">
                        <a href={DataSongmics.affiliateLink} onClick={googleAnalyticsEventFunctionSongmics} className="font-weight-bold" style={{ "fontSize": "1.3rem" }}>{ DataSongmics.brand } { DataSongmics.name }</a>
                        <p className="font-weight-bold" style={{ "fontSize": "1.5rem" }}><span className="badge badge-warning">€ { DataSongmics.price }</span></p>
                    </div>
                    <div className="col-sm">
                        <a href={DataImperioLift.affiliateLink} onClick={googleAnalyticsEventFunctionImperioLift} className="font-weight-bold" style={{ "fontSize": "1.3rem" }}>{ DataImperioLift.brand } { DataImperioLift.name }</a>
                        <p className="font-weight-bold" style={{ "fontSize": "1.5rem" }}><span className="badge badge-warning">€ { DataImperioLift.price }</span></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheapDesks