import React from "react"

const ProductSummary = ({ summary }) => {
    return (
        <>
            <div className="mb-5">
                <a name="summary"></a>
                <h2>Zusammenfassung</h2>
                <p dangerouslySetInnerHTML={{ __html: summary }}></p>
            </div>
        </>
    )
}

export default ProductSummary
