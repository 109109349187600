import React from "react"

const ProductQuestions = ({ questionsAffiliateLink, name, brand, gaEventFunction, questionsTotal }) => {
    const text = questionsTotal > 0
        ? `${questionsTotal} Fragen und Antworten zum ${brand} ${name} verfügbar.`
        : `Sei der Erste der Fragen und Antworten zum ${brand} ${name} stellt.`;

    const questionsButtonText = questionsTotal > 0
        ? `${questionsTotal} Fragen und Antworten lesen`
        : 'Fragen und Antworten';

    return (
        <>
            <div className="mb-5">
                <a name="questions"></a>
                <h2>Fragen und Antworten</h2>
                <p>{text}</p>
                <a
                    className="btn btn-success"
                    href={questionsAffiliateLink}
                    onClick={gaEventFunction}
                    role="button"
                >❓ {questionsButtonText}</a>
            </div>
        </>
    )
}

export default ProductQuestions




