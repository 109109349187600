import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import ProductSimilar from "./product-similar";
import DataFlexispot from "../data/flexispot-h2w";
import DataImperioLift from "../data/imperio-lift";
import DataEadjust from "../data/eadjust"

const SimilarProducts = ({ data }) => {

    const googleAnalyticsEventFunctionFlexispot = function (e, val = "Flexispot") {
        //e.preventDefault()
        trackCustomEvent({
            category: "Affiliate Link",
            action: "Click", 
            label: val,
        })
        console.log("click event: " + val)
    };

    const googleAnalyticsEventFunctionImperio = function (e, val = "Imperio Lift") {
        //e.preventDefault()
        trackCustomEvent({
            category: "Affiliate Link",
            action: "Click", 
            label: val,
        })
        console.log("click event: " + val)
    };

    const googleAnalyticsEventFunctionEadjust = function (e, val = "Eadjust") {
        //e.preventDefault()
        trackCustomEvent({
            category: "Affiliate Link",
            action: "Click", 
            label: val,
        })
        console.log("click event: " + val)
    };

    return (
        <>
            <a name="similarProducts"></a>
            <h2 className="mb-3">Weitere preiswerte Stehschreibtische</h2>
            <div className="card-deck mb-5">
                <ProductSimilar
                    name={DataFlexispot.name}
                    rating={DataFlexispot.rating}
                    reviewsAmount={DataFlexispot.reviewsAmount}
                    brand={DataFlexispot.brand}
                    internalLink={DataFlexispot.internalLink}
                    gaEventFunction={googleAnalyticsEventFunctionFlexispot}
                    affiliateLink={DataFlexispot.affiliateLink}
                    image={data.flexispotImage.childImageSharp.fluid}
                />
                <ProductSimilar
                    name={DataImperioLift.name}
                    rating={DataImperioLift.rating}
                    reviewsAmount={DataImperioLift.reviewsAmount}
                    brand={DataImperioLift.brand}
                    internalLink={DataImperioLift.internalLink}
                    gaEventFunction={googleAnalyticsEventFunctionImperio}
                    affiliateLink={DataImperioLift.affiliateLink}
                    image={data.imperioImage.childImageSharp.fluid}
                />
                <ProductSimilar
                    name={DataEadjust.name}
                    rating={DataEadjust.rating}
                    reviewsAmount={DataEadjust.reviewsAmount}
                    brand={DataEadjust.brand}
                    internalLink={DataEadjust.internalLink}
                    gaEventFunction={googleAnalyticsEventFunctionEadjust}
                    affiliateLink={DataEadjust.affiliateLink}
                    image={data.eadjustImage.childImageSharp.fluid}
                />
            </div>
        </>
    )
}

export const otherProductImage = graphql`
  fragment otherProductImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default props => (
    <StaticQuery
        query={graphql`
            query {
                flexispotImage: file(relativePath: { eq: "image-flexispot-h2w.jpg" }) {
                    ...otherProductImage
                }
                imperioImage: file(relativePath: { eq: "image-imperio-lift.jpg" }) {
                    ...otherProductImage
                }
                eadjustImage: file(relativePath: { eq: "image-eadjust.jpg" }) {
                    ...otherProductImage
                }
            }
      `}
        render={data => <SimilarProducts data={data} {...props} />}
    />
)